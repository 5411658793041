import * as React from 'react'
import {graphql} from 'gatsby'
import {useTranslation} from 'gatsby-plugin-react-i18next'

import {Seo, Layout, SupportPage} from '../components'
import {greenTheme} from '../theme'

const Support = () => {
  const {t} = useTranslation()

  return (
    <Layout themeConfig={greenTheme}>
      <Seo title={t('pages.support.seo.title')} description={t('pages.support.seo.description')} />
      <SupportPage />
    </Layout>
  )
}

export default Support

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
